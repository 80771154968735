import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, Hero } from 'components';
import { MainTitle, Subtitle, RightsGrid } from 'ui';
import Right from 'components/Right';

const Expertises = ({ data: { datoCmsExpertisePage, allDatoCmsRight } }) => (
  <>
    <SEO
      title={datoCmsExpertisePage?.metaTags?.title}
      description={datoCmsExpertisePage?.metaTags?.description}
    />

    <Layout
      hero={
        <Hero cover={datoCmsExpertisePage?.cover}>
          <MainTitle as="h1" variant="light">
            {datoCmsExpertisePage?.title}
          </MainTitle>
          {Boolean(datoCmsExpertisePage?.description) && (
            <Subtitle variant="light">
              {datoCmsExpertisePage?.description}
            </Subtitle>
          )}
        </Hero>
      }
    >
      <RightsGrid>
        {(allDatoCmsRight.edges || []).map(({ node }, index) => (
          <Right
            right={node}
            key={node.slug}
            layout={index % 2 === 0 ? 'even' : 'odd'}
          />
        ))}
      </RightsGrid>
    </Layout>
  </>
);

export const query = graphql`
  query expertises {
    datoCmsExpertisePage {
      title
      description
      metaTags {
        title
        description
      }
      cover {
        gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
      }
    }
    allDatoCmsRight(sort: { fields: order }) {
      edges {
        node {
          slug
          name
          icon {
            url
          }
          cover {
            gatsbyImageData(aspectRatio: 1.77, layout: FULL_WIDTH)
          }
          specialties {
            name
            slug
          }
        }
      }
    }
  }
`;

export default Expertises;
